export default function FeedbackTable({data, filterFeedback, matchCount}) {
  return (  
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
            <table className="min-w-full divide-y divide-gray-300" id="feedback-table">
              <thead className="font-mono">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Browser
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      OS
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Category
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {matchCount} / {data.length}
                    </th>
                  </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data && data.map((feedback) => {
                  if (filterFeedback(feedback)) {
                    return
                  }
                  return (
                    <tr key={feedback.taskId} className={feedback.type === "Other" && "bg-yellow-50" || feedback.type === "Feature Request" && "bg-green-50" || "bg-red-50"}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {feedback.browser}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {feedback.os}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {feedback.type}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-900 max-w-3xl">
                        {feedback.feedback}
                        &nbsp;(<a href={`https://app.asana.com/0/1199178362774117/${feedback.taskId}/f` } target="_blank" className="text-gray-600 hover:underline">{feedback.date} ➚</a>)
                      </td>
                    </tr>
                  )}
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
  