export default function SelectBrowserField({setBrowserFilter, availableBrowsers}) {
    return (
        <div>
        <label htmlFor="location" className="block text-sm font-medium text-gray-900">
            Browser
        </label>
        <select
            id="location"
            name="location"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue="All"
            onChange={e => setBrowserFilter(e.target.value)}
        >
            <option>All</option>
            {availableBrowsers.map((browser) => (
                <option key={browser}>{browser}</option>
            ))}
        </select>
        </div>
    )
}
  