export default function SearchFeedbackField({setQuery}) {
  return (
    <div className="flex-grow">
      <label htmlFor="query" className="block text-sm font-medium text-gray-900">
        Search
      </label>
      <input
        type="text"
        name="query"
        id="query"
        className="mt-1 block w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        placeholder={"Contains..."}
        onChange={e => setQuery(e.target.value)}
      />
    </div>
  )
}