import { useState, useEffect } from 'react'
import FeedbackTable from './FeedbackTable'
import SearchFeedbackField from './SearchFeedbackField'
import SelectBrowserField from './SelectBrowserField'
import SelectOsField from './SelectOsField'
import SelectFeedbackTypeField from './SelectFeedbackTypeField'
import { useCSVReader } from 'react-papaparse'

export default function CSVReader() {
  const [feedbackData, setFeedbackData] = useState([])
  const [matchCount, setMatchCount] = useState(0)
  const [query, setQuery] = useState("")
  const [osFilter, setOsFilter] = useState("All")
  const [browserFilter, setBrowserFilter] = useState("All")
  const [typeFilter, setTypeFilter] = useState("All")
  const [availableBrowsers, setAvailableBrowsers] = useState([])
  const [availableOses, setAvailableOses] = useState([])
  const [availableTypes, setAvailableTypes] = useState([])
  const [printedList, setPrintedList] = useState(false)
  const { CSVReader } = useCSVReader()

  function processFeedbackData(data) {
    const postLaunchResults = data.filter(i => i["Created At"] > "2022-04-11")
    const browsers = []
    const osMajors = []
    const types = []
    const cleanedResults = postLaunchResults.map(i => {
        let feedback = i["Notes"]
        let os = ""
        let browser = ""
        let type = ""
        if(feedback && feedback.split("---").length === 2) {
            const t = feedback.split("---")[1].trim()
            os = t.slice(t.indexOf("MacOS Version:")+15,)
            browser =  t.slice(t.indexOf("App Version:")+12,t.indexOf("ATB")).trim()
            type =  t.slice(t.indexOf("Category:")+9,t.indexOf("Sub-category")).trim()
            feedback = feedback.split("---")[0].trim()

            if (!browsers.includes(browser)) {
              browsers.push(browser)
            }
            if (!osMajors.includes(os.slice(0,2))) {
              osMajors.push(os.slice(0,2))
            }
            if (!types.includes(type)) {
              types.push(type)
            }
        }
        return {
            taskId: i["Task ID"],
            date: i["Created At"],
            browser,
            os,
            type,
            feedback
        }
    })
    setFeedbackData(cleanedResults)
    setAvailableBrowsers(browsers)
    setAvailableOses(osMajors)
    setAvailableTypes(types)
    setMatchCount(cleanedResults.length)
  }

  useEffect(() => {
    if(document.getElementById("feedback-table") && document.getElementById("feedback-table").rows) {
      setMatchCount(document.getElementById("feedback-table").rows.length - 1)
    }
  },[query, browserFilter, osFilter, typeFilter])

  function filterFeedback(i) {
    const querySkip = (query !== "" && i.feedback && i.feedback.toLowerCase().indexOf(query.toLowerCase()) === -1)
    const browserSkip = (browserFilter !== "All" && i.browser !== browserFilter)
    const osSkip = (osFilter !== "All" && osFilter !== i.os.slice(0,2))
    const typeSkip = (typeFilter !== "All" && i.type !== typeFilter)

    if(osSkip || browserSkip || typeSkip || querySkip) {
      return true // skip it
    } else {
      return false // show it
    }
  }

  function printWordListToConsole() {
    const throwAwayWords = ["the", "to", "and", "it", "be", "is", "in", "for", "would", "on", "of", "like", "that", "have", "this", "my", "not", "when", "you", "as", "with", "but", "for", "from", "or", "if", "use", "an", "so", "all", "can", "app", "are", "way", "also", "there", "new", "see", "able", "could", "when", "other", "some", "was", "does", "quot", "which", "doesn", "has", "without", "just", "using", "very", "make", "time", "more", "want", "don", "will", "right", "only", "them", "your", "get", "after", "into", "what", "should", "any", "where", "out", "having", "even", "now", "something", "well", "etc", "know", "://", "than", "they", "being", "lot", "because", "always", "maybe", "used", "had", "been", "between", "such", "www", "through", "these", "thing", "its", "were" ]
    const wordCounts = {}
    for (const f of feedbackData) {
      const words = f.feedback && f.feedback.toLowerCase().split(/\b/) || []
        for (const w of words) {
          if (w.trim().length > 2 && !throwAwayWords.includes(w.trim())) {
            wordCounts[w] = (wordCounts[w] || 0) + 1
          }
        }
    }
    const entries = Object.entries(wordCounts)
    const sorted = entries.sort((a, b) => b[1] - a[1])
    console.log(sorted.slice(0,201))
    setPrintedList(true)
  }

  return (
    <div className="p-8">
      <CSVReader onUploadAccepted={(results) => {processFeedbackData(results.data)}} config={{header: true, dynamicTyping: true}}>
        {({getRootProps, acceptedFile, ProgressBar,}) => (
          <>
            {!acceptedFile && feedbackData.length === 0 && 
              <>
                <div>
                  <span>1. </span>
                  <a className="text-blue-700 underline" href="https://app.asana.com/-/csv?id=1199178362774117" target="_blank">Download CSV from Asana</a>
                </div>
                <div>
                  <span>2. </span>
                  <button type="button" className="text-blue-700 underline" {...getRootProps()}>Choose downloaded file</button>
                </div>
              </>  
            }
            <ProgressBar />
          </>
        )}
      </CSVReader>
      {feedbackData.length > 0 &&
        <>
          <div className="flex space-x-2 flex-wrap">
            <SelectBrowserField setBrowserFilter={setBrowserFilter} availableBrowsers={availableBrowsers}/>
            <SelectOsField setOsFilter={setOsFilter} availableOses={availableOses}/>
            <SelectFeedbackTypeField setTypeFilter={setTypeFilter} availableTypes={availableTypes}/>
            <SearchFeedbackField setQuery={setQuery} />
          </div>
          {printedList && <p className="text-sm text-gray-700 mt-4">Check browser console output</p>}
          {!printedList && <button className="text-sm text-blue-700 mt-4 underline" onClick={printWordListToConsole}>Print word list to console</button>}
          <FeedbackTable data={feedbackData} filterFeedback={filterFeedback} matchCount={matchCount}/>
        </>
      }
    </div>
  )
}